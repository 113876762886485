import AppCard from "./components/AppCard";

const { Grid } = require("@mui/material");

const appData = [
  { title: "Sudoku Solver", description: "Instantly solve Sudoku puzzles", subdomain: "sudoku" },
  { title: "Albion Crafting", description: "Optimize crafting in Albion", subdomain: "albion" },
];

function App() {
  return (
    <Grid container spacing={1} padding="2%" >
      {appData.sort((a, b) => a.title.localeCompare(b.title)).map((item) => (
        <Grid item>
          <AppCard title={item.title} description={item.description} subdomain={item.subdomain} />
      </Grid>
      ))}
    </Grid>
  );
}

export default App;
