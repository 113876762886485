import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";

const AppCard = ({ title, description, subdomain }) => {

	const fullDomain = "https://" + subdomain + ".dinamietapp.co.za/";

	const handleClick = () => {
		window.location.href = fullDomain;
	};

	return (
		<Card sx={{ width: "192px" }}>
			<CardActionArea onClick={handleClick}>
				<CardMedia
					component="img"
					image={fullDomain + "logo192.png"}
					width="192px"
				/>
				<CardContent>
					<Typography variant="h5">
						{title}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{description}
					</Typography>
					<a href={fullDomain} />
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default AppCard;
